<!--  -->
<template>
  <div class="layout">
    <!-- <div class="layout-top sticky">
      <div class="width80" style="padding: 0">
        <div class="layout-top-left">xxxxx</div>
        <div class="layout-top-right">
          顶部信息
          <a-popover :title="langText" trigger="hover">
            <template #content>
              <div class="cursor" @click="toggleLanguag('en')">英文</div>
              <div
                class="cursor"
                style="margin-top: 5px"
                @click="toggleLanguag('sp')"
              >
                西班牙
              </div>
            </template>
            <span class="cursor" style="margin-left: 10px">
              language：{{ langText }}
            </span>
          </a-popover>
        </div>
      </div>
    </div> -->
    <div
      class="layout-menus"
      v-if="!isMobile"
      :style="{
        background:
          !isfiexed &&
          subMneuLeft != 'products' &&
          subMneuLeft != 'applications'
            ? 'transparent'
            : '#fff',
      }"
    >
      <div
        class="width80"
        :style="{ padding: !isfiexed ? '20px 0' : '10px 0' }"
      >
        <div class="layout-menus-logo">
          <img
            src="/static/img/logo.png"
            alt=""
            :style="{
              width: !isfiexed ? '110%' : '100%',
              height: !isfiexed ? '5vh' : '4vh',
            }"
          />
        </div>
        <div class="layout-menus-cont">
          <div class="menus">
            <div
              class="menus-item"
              @click.stop="secondaryMenuClickHanlder('home')"
            >
              <div
                class="menus-item-line"
                :class="[subMneuLeft == 'home' ? 'menus_item_select' : '']"
                @mouseenter="topMenusHandleMouseEnter('home')"
              >
                HOME
              </div>
            </div>
            <div class="menus-item">
              <div
                class="menus-item-line"
                :class="[subMneuLeft == 'products' ? 'menus_item_select' : '']"
                @mouseenter="topMenusHandleMouseEnter('products')"
                @click.stop="secondaryMenuClickHanlder('products?c=-1')"
              >
                PRODUCTS
              </div>
              <div
                class="sub_menu"
                @mouseleave="topMenusHandleMouseLeave('products&applications')"
                :style="{
                  top: !isfiexed ? '75px' : '57px',
                }"
                v-if="
                  (subMneuLeft == 'products' ||
                    subMneuLeft == 'applications') &&
                  subMneuLeftVisible
                "
              >
                <div class="sub_menu_box">
                  <img
                    src="/static/img/img8-2.jpg"
                    alt=""
                    style="align-self: center; width: 35%; margin-right: 30px"
                  />
                  <div class="sub_menu_left" v-if="subMneuLeft === 'products'">
                    <div
                      class="sub_menu_item"
                      :class="[
                        subMneuLeftSelect === 'Architectural Glass'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="secondaryMenuClickHanlder('products?c=0')"
                      @mouseenter="handleMouseEnter('Architectural Glass')"
                      @mouseleave="handleMouseLeave('Architectural Glass')"
                    >
                      Architectural Glass
                      <img
                        class="youjiantou"
                        src="static/img/youjiantou.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="sub_menu_item"
                      :class="[
                        subMneuLeftSelect === 'Mirror'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="secondaryMenuClickHanlder('products?c=1')"
                      @mouseenter="handleMouseEnter('Mirror')"
                      @mouseleave="handleMouseLeave('Mirror')"
                    >
                      Mirror
                      <img
                        class="youjiantou"
                        src="static/img/youjiantou.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="sub_menu_item"
                      :class="[
                        subMneuLeftSelect === 'Decoration Glass'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="secondaryMenuClickHanlder('products?c=2')"
                      @mouseenter="handleMouseEnter('Decoration Glass')"
                      @mouseleave="handleMouseLeave('Decoration Glass')"
                    >
                      Decoration Glass
                      <img
                        class="youjiantou"
                        src="static/img/youjiantou.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="sub_menu_item"
                      :class="[
                        subMneuLeftSelect === 'Float Glass'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="secondaryMenuClickHanlder('products?c=3')"
                      @mouseenter="handleMouseEnter('Float Glass')"
                      @mouseleave="handleMouseLeave('Float Glass')"
                    >
                      Float Glass
                      <img
                        class="youjiantou"
                        src="static/img/youjiantou.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="sub_menu_item"
                      :class="[
                        subMneuLeftSelect === 'Special Glass'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="secondaryMenuClickHanlder('products?c=4')"
                      @mouseenter="handleMouseEnter('Special Glass')"
                      @mouseleave="handleMouseLeave('Special Glass')"
                    >
                      Special Glass
                      <img
                        class="youjiantou"
                        src="static/img/youjiantou.svg"
                        alt=""
                      />
                    </div>
                    <div
                      class="sub_menu_item"
                      :class="[
                        subMneuLeftSelect === 'WPC Panel'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="secondaryMenuClickHanlder('products?c=5')"
                      @mouseenter="handleMouseEnter('WPC Panel')"
                      @mouseleave="handleMouseLeave('WPC Panel')"
                    >
                      WPC Panel
                      <img
                        class="youjiantou"
                        src="static/img/youjiantou.svg"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="sub_menu_right" v-if="subMneuLeft === 'products'">
                    <div
                      class="sub_menu_right_item"
                      v-for="(item, ii) in subMmenuRightList"
                      :key="ii"
                      @click.stop="subMenuRightItemClickHanlder(ii)"
                    >
                      {{ item.text }}
                    </div>
                  </div>
                  <div
                    class="sub_menu_right"
                    v-if="subMneuLeft === 'applications'"
                  >
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Partition'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @mouseenter="handleMouseEnter('Partition')"
                      @mouseleave="handleMouseLeave('Partition')"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=0')
                      "
                    >
                      Partition
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Shower Door'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @mouseenter="handleMouseEnter('Shower Door')"
                      @mouseleave="handleMouseLeave('Shower Door')"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=1')
                      "
                    >
                      Shower Door
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Padel Court'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=2')
                      "
                      @mouseenter="handleMouseEnter('Padel Court')"
                      @mouseleave="handleMouseLeave('Padel Court')"
                    >
                      Padel Court
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Curtain Wall'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=3')
                      "
                      @mouseenter="handleMouseEnter('Curtain Wall')"
                      @mouseleave="handleMouseLeave('Curtain Wall')"
                    >
                      Curtain Wall
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Railing&balustrades'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=4')
                      "
                      @mouseenter="handleMouseEnter('Railing&balustrades')"
                      @mouseleave="handleMouseLeave('Railing&balustrades')"
                    >
                      Railing&balustrades
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Glass Louvers | Glass Blinds'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=5')
                      "
                      @mouseenter="
                        handleMouseEnter('Glass Louvers | Glass Blinds')
                      "
                      @mouseleave="
                        handleMouseLeave('Glass Louvers | Glass Blinds')
                      "
                    >
                      Glass Louvers | Glass Blinds
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Roof & Skylights'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=6')
                      "
                      @mouseenter="handleMouseEnter('Roof & Skylights')"
                      @mouseleave="handleMouseLeave('Roof & Skylights')"
                    >
                      Roof & Skylights
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Glass Door'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=7')
                      "
                      @mouseenter="handleMouseEnter('Glass Door')"
                      @mouseleave="handleMouseLeave('Glass Door')"
                    >
                      Glass Door
                    </div>
                    <div
                      class="sub_menu_right_item"
                      :class="[
                        subMneuLeftSelect === 'Glass Splashbacks'
                          ? 'sub_menu_item_select'
                          : '',
                      ]"
                      @click.stop="
                        secondaryMenuClickHanlder('applications?a=8')
                      "
                      @mouseenter="handleMouseEnter('Glass Splashbacks')"
                      @mouseleave="handleMouseLeave('Glass Splashbacks')"
                    >
                      Glass Splashbacks
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="menus-item"
              @click.stop="secondaryMenuClickHanlder('about')"
            >
              <div
                class="menus-item-line"
                :class="[subMneuLeft == 'about' ? 'menus_item_select' : '']"
                @mouseenter="topMenusHandleMouseEnter('about')"
              >
                ABOUT US
              </div>
            </div>
            <div
              class="menus-item"
              @click.stop="secondaryMenuClickHanlder('applications?a=-1')"
            >
              <div
                class="menus-item-line"
                :class="[
                  subMneuLeft == 'applications' ? 'menus_item_select' : '',
                ]"
                @mouseenter="topMenusHandleMouseEnter('applications')"
              >
                APPLICATIONS
              </div>
            </div>
            <div
              class="menus-item"
              @click.stop="secondaryMenuClickHanlder('contactUs')"
            >
              <div
                class="menus-item-line"
                :class="[subMneuLeft == 'contactUs' ? 'menus_item_select' : '']"
                @mouseenter="topMenusHandleMouseEnter('contactUs')"
              >
                CONTACT US
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-mobile-menus" v-else>
      <div class="layout-menus-cont">
        <div class="menus">
          <div
            class="menus-item"
            @click.stop="secondaryMenuClickHanlder('home')"
          >
            <div
              class="menus-item-line"
              :class="[subMneuLeft == 'home' ? 'menus_item_select' : '']"
              @mouseenter="topMenusHandleMouseEnter('home')"
            >
              Home
            </div>
          </div>
          <div class="menus-item">
            <div
              class="menus-item-line"
              :class="[subMneuLeft == 'products' ? 'menus_item_select' : '']"
              @mouseenter="topMenusHandleMouseEnter('products')"
              @click.stop="secondaryMenuClickHanlder('products?c=-1')"
            >
              Products
            </div>
          </div>
          <div
            class="menus-item"
            @click.stop="secondaryMenuClickHanlder('about')"
          >
            <div
              class="menus-item-line"
              :class="[subMneuLeft == 'about' ? 'menus_item_select' : '']"
              @mouseenter="topMenusHandleMouseEnter('about')"
            >
              About
            </div>
          </div>
          <div
            class="menus-item"
            @click.stop="secondaryMenuClickHanlder('applications?a=-1')"
          >
            <div
              class="menus-item-line"
              :class="[
                subMneuLeft == 'applications' ? 'menus_item_select' : '',
              ]"
              @mouseenter="topMenusHandleMouseEnter('applications')"
            >
              Applications
            </div>
          </div>
          <div
            class="menus-item"
            @click.stop="secondaryMenuClickHanlder('contactUs')"
          >
            <div
              class="menus-item-line"
              :class="[subMneuLeft == 'contactUs' ? 'menus_item_select' : '']"
              @mouseenter="topMenusHandleMouseEnter('contactUs')"
            >
              Tel
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout-cont">
      <router-view :key="$route.path + $route.query.t"></router-view>
    </div>
    <div class="layout-footer" @click="secondaryMenuClickHanlder('contactUs')">
      <div class="layout-footer-cont">
        <div class="layout-footer-cont-item">
          <img style="width: 140px" src="/static/img/logo.png" alt="" />
        </div>
        <div class="layout-footer-cont-item">
          <div>
            <img src="/static/img/call.svg" alt="call" />
            <a href="tel:008618669775996" aria-label="+008618669775996">
              0086 18669775996
            </a>
          </div>
          <div style="margin: 20px 0">
            <img src="/static/img/emil.svg" alt="emil" />
            info@universglass.com
          </div>
          <div>
            <img src="/static/img/dot.svg" alt="dot" />
            12A, Heda Center Building, No. 179 Tailiu Road, Qingdao China
          </div>
        </div>
        <div class="layout-footer-cont-item flex">
          <span class="layout-footer-cont-item-title">Social Links</span>
          <div class="flex">
            <a
              target="_blank"
              rel="nofollow"
              aria-label="Facebook"
              href="https://www.facebook.com/qduniversglass/"
            >
              <img src="static/img/facebook.svg" alt="facebook" />
            </a>
            <a
              target="_blank"
              rel="nofollow"
              aria-label="lingying"
              href="https://www.linkedin.com/company/96912682/admin/feed/posts/"
            >
              <img src="static/img/lingying.svg" alt="lingying" />
            </a>
            <a
              target="_blank"
              rel="nofollow"
              aria-label="instagram"
              href="https://www.instagram.com/universgroup_china"
            >
              <img src="static/img/instagram.svg" alt="lingying" />
            </a>
            <a
              target="_blank"
              rel="nofollow"
              aria-label="twitter"
              href="https://twitter.com/Universglass"
            >
              <img src="static/img/icon-twitternew2.svg" alt="lingying" />
            </a>
          </div>
        </div>
      </div>
      <hr class="layout-footer-hr" />
      <div class="layout-footer-log">
        <span>
          © 2023. All rights reserved.Qingdao Univers Glass Co., Ltd.
        </span>
      </div>
    </div>
    <div v-if="!isMobile" id="components-back-top-demo-custom">
      <a-back-top />
    </div>
  </div>
</template>
<!-- <script setup>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
// import { useI18n } from 'vue-i18n';
// const { locale } = useI18n();

const router = useRouter();
let routePath = ref('');
// const langText = ref(localStorage.getItem('lang') || '');

// const toggleLanguag = (val) => {
//   locale.value = val;
//   localStorage.setItem('lang', val);
//   location.reload();
// };
const current = ref(['home']);
const setCurrent = function (v) {
  current.value[0] = v;
};
watch(
  () => router.currentRoute.value,
  (newValue) => {
    window.scrollTo({
      top: 0, // 滚动到页面顶部
      behavior: 'smooth', // 平滑滚动
    });
    // if (newValue?.fullPath?.indexOf('?c=') > 1) {
    //   setCurrent(`${newValue?.fullPath?.slice(1) || 'home'}`);
    // } else if (newValue?.fullPath?.indexOf('?a=') > 1) {
    //   setCurrent(`${newValue?.fullPath?.slice(1) || 'home'}`);
    // } else {
    //   setCurrent(`${newValue?.name || 'home'}`);
    // }
    console.log(newValue);
    setCurrent(`${newValue?.name || 'home'}`);
    routePath.value = newValue;
  },
  { immediate: true }
);
</script> -->
<script>
import { appLicktionMeunsItemList } from '/static/application.js';
import { MeunsItemList } from '/static/products.js';

export default {
  //import引入的组件需要注入到对象中才能使用
  name: 'layout-index',
  components: {},
  setup() {},
  data() {
    return {
      isfiexed: false,
      subMneuLeftVisible: false,
      subMneuLeft: 'home',
      subMneuLeftSelect: '',
      bojIndex: -1,
      subMmenuRightList: [],
      MeunsItemList,
      appLicktionMeunsItemList,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
  },
  watch: {
    $route(n) {
      console.log(n);
      window.scrollTo({
        top: 0, // 滚动到页面顶部
        behavior: 'smooth', // 平滑滚动
      });
      if (n) {
        if (n.name) {
          this.subMneuLeft = n?.name?.split('?')?.[0] || 'home';
        }
      }
    },
  },
  methods: {
    menusClick(v) {
      console.log(v);
      const { key } = v;
      this.$router.push(`/${key}`);
    },
    aboutClickHanlder() {
      this.$router.push(`/about`);
    },
    secondaryMenuClickHanlder(v) {
      if (v === 'applications?a=-1') {
        this.$router.push(`/${v}`);
        setTimeout(() => {
          this.$router.go(0);
        }, 10);
      } else {
        this.$router.push(`/${v}`);
      }
    },
    scrollToTop() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 100) this.isfiexed = true;
      else this.isfiexed = false;
      this.subMneuLeftVisible = false;
    },
    handleMouseEnter(v) {
      console.log('鼠标移入');
      this.subMneuLeftSelect = v;
      if (this.subMneuLeft === 'products') {
        console.log(this.MeunsItemList);
        let boj = this.MeunsItemList?.find((f) => f.text === v);
        this.bojIndex = this.MeunsItemList?.findIndex((f) => f.text === v);
        this.subMmenuRightList = [...(boj.childMenu || [])];
      }
      if (this.subMneuLeft === 'appliction') {
        console.log(this.appLicktionMeunsItemList);
      }
    },
    handleMouseLeave(v) {
      console.log('鼠标移出');
      console.log(v);
    },
    topMenusHandleMouseEnter(v) {
      this.subMmenuRightList = [];
      this.subMneuLeft = '';
      this.subMneuLeft = v;
      if (v === 'products' || v === 'applications') {
        this.subMneuLeftVisible = true;
      }
    },
    topMenusHandleMouseLeave() {
      // this.subMmenuRightList = [];
      // this.subMneuLeft = '';
      this.subMneuLeftVisible = false;
    },
    subMenuRightItemClickHanlder(v) {
      this.$router.push(`/${this.subMneuLeft}?c=${this.bojIndex}&class=${v}`);
    },
  },
};
</script>
<style moudle></style>
<style scoped lang="scss">
.layout {
  // overflow-x: hidden;
  position: relative;
  &-top {
    padding: 5px 0;
    font-size: 12px;
    width: 100%;
    background: #14172a;
    color: #fff;
  }
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;
  }
  &-menus {
    position: fixed;
    z-index: 10;
    top: 0;
    width: 100%;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    &-logo {
      transition: all 0.4s;
      img {
        width: 100%;
        height: 4vh;
        transition: all 0.4s;
      }
    }

    &-cont {
      min-width: 50%;
      text-align: right;
      flex: 1;
      margin-left: 20%;
      .menus {
        display: flex;
        justify-content: space-around;
        align-items: center;
        &-item {
          cursor: pointer;
          font-size: 1.1rem;

          .sub_menu {
            position: absolute;
            top: 75px;
            left: 0;
            right: 0;
            width: 100vw;
            // height: 40vh;
            background: #fff;
            z-index: 99999;
            text-align: left;
            border-top: 1px solid #ccc;
            box-shadow: 0 10px 15px 0px rgba(0, 0, 0, 0.2);
            .sub_menu_box {
              margin: 0 auto;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              width: 80%;
              // min-height: 40vh;
              color: black;
              padding: 20px 0;
              .sub_menu_left {
                .sub_menu_item {
                  padding: 10px 10px 10px 20px;
                  transition: all 0.3s;
                  position: relative;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .youjiantou {
                    color: black;
                    margin-left: 20px;
                    opacity: 0;
                    transition: all 0.3s;
                  }
                }
                .sub_menu_item_select {
                  background: rgba(204, 204, 204, 0.3);
                }
                .sub_menu_item_select .youjiantou {
                  opacity: 1;
                }
                .sub_menu_item:hover {
                  background: rgba(204, 204, 204, 0.3);
                }
                .sub_menu_item:hover .youjiantou {
                  opacity: 1;
                }
              }
              .sub_menu_right {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                padding: 0 30px;
                .sub_menu_right_item {
                  padding: 8px 25px;
                  transition: all 0.3s;
                  border-radius: 6px;
                  width: 50%;
                  margin-bottom: 20px;
                }
                .sub_menu_right_item:hover {
                  background: rgba(204, 204, 204, 0.3);
                }
              }
            }
          }
        }
        // :class="[subMneuLeft == 'home' ? 'menus_item_line_select' : '']"
        .menus_item_select {
          color: #303b8a;
          position: relative;
        }
        .menus_item_select::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #303b8a;
          bottom: -10px;
          left: 0;
        }
      }
    }
  }
  .width80 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    padding: 30px 0;
  }
  &-cont {
    // padding: 10px;
    min-height: calc(100vh - 100px);
    position: relative;
  }
  &-footer {
    position: relative;
    // &-back {
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   left: 0;
    //   top: 0;
    //   background: black;
    //   opacity: 0.4;
    // }
    width: 100%;
    background: black;
    opacity: 0.9;
    padding: 6vh 0;
    &-cont {
      width: 70%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-item {
        display: flex;
        flex-direction: column;
        align-content: center;
        font-weight: 600;
        text-align: left;
        cursor: pointer;
        font-size: 18px;
        color: #bbbbbb;
        a {
          color: #bbbbbb;
        }
        &-title {
          font-size: 1.4rem !important;
          font-weight: 700;
          margin-bottom: 10px;
          color: #bbbbbb;
        }
      }
      .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          margin: 0 16px;
          transition: all 0.4s;
          border-radius: 100%;
          img {
            width: 32px;
          }
        }
        a:hover {
          background: radial-gradient(
              circle farthest-corner at 35% 100%,
              #fec564,
              transparent 50%
            ),
            radial-gradient(
              circle farthest-corner at 10% 140%,
              #feda7e,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 0 -25%,
              #5258cf,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 20% -50%,
              #5258cf,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 100% 0,
              #893dc2,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 60% -20%,
              #893dc2,
              transparent 50%
            ),
            radial-gradient(
              ellipse farthest-corner at 100% 100%,
              #d9317a,
              transparent
            ),
            linear-gradient(
              #6559ca,
              #bc318f 30%,
              #e42e66 50%,
              #fa5332 70%,
              #ffdc80 100%
            );
        }
      }
    }
    &-hr {
      width: 80%;
      margin: 0;
      margin: 16px auto;
      opacity: 0.2;
    }
    &-log {
      width: 80%;
      margin: 35px auto 0px;
      text-align: center;
      span {
        color: #fff;
        margin-left: 5%;
      }
    }
  }
  #components-back-top-demo-custom .ant-back-top {
    bottom: 100px;
  }
  #components-back-top-demo-custom .ant-back-top-inner {
    height: 40px;
    width: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: #eb7d1e;
    color: #fff;
    text-align: center;
    font-size: 20px;
  }
  .lang-select {
    color: #fff;
    font-size: 20px;
  }
  .layout-mobile-menus {
    width: 100%;
    height: 50px;
    .layout-menus-cont {
      margin: 0;
      .menus {
        padding: 18px 0;
        .menus-item {
          font-size: 0.9rem;
        }
      }
    }
  }
}
</style>
